@import "../../../assets/styles/mixins";

.student-hero {
  height: 30rem;
  display: flex;
  align-items: center;
  background-image: url("../../../assets/images/main-page/hero.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @include tablet {
    background-image: url("../../../assets/images/main-page/hero-tablet.svg");
  }

  @include mobile {
    height: 65rem;
    padding-top: 0;
    background-image: url("../../../assets/images/main-page/hero-mobile.svg");
  }

  &__wrapper {
    width: 100%;

    & > h1 {
      margin-bottom: 1rem;
      font-size: 3rem;
      color: white;
    }

    & > p {
      width: 45rem;
      color: white;

      @include tablet {
        width: 100%;
      }

      @include mobile {
        font-size: 1.2rem;
      }
    }
  }
}
