.dropzone {
  height: 33rem;
  padding: 1.5rem;
  border: 0.15rem dashed var(--main-blue);
  border-radius: 0.5rem;

  &__files-list-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    & h4 {
      margin: 0;
      font-size: large;
    }

    & p {
      font-size: small;
      margin-bottom: 1rem;
    }
  }

  &__files-list {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 1rem;
  }

  &__files-expand {
    height: 6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #eaeaea;
  }

  &__files-list-panel {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__uploaded-files-info {
    & > h4 {
      margin: 0;
      font-size: large;
    }

    & > p {
      font-size: small;
      margin: 0;
    }
  }

  &__buttons-wrapper {
    display: flex;
  }

  &__button {
    width: 8.5rem;
    justify-content: center;

    &:first-child {
      margin-right: 1rem;
    }
  }

  &__files-placeholder {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    & > img {
      width: 5rem;
      margin-bottom: 2rem;
    }

    & > h3 {
      margin-bottom: 0.5rem;
    }

    & > p {
      margin: 0;
      font-size: medium;
    }
  }
}
