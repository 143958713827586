@import "../../assets/styles/mixins";

.auth-page {
  height: 100vh;
  width: 100%;
  background-image: url("../../assets/images/auth-page/background.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @include mobile {
    background-image: none;
  }

  &__form-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 35rem;
    padding: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;

    @media screen and (min-width: 1024px) and (max-width: 1280px) {
      width: 42rem;
    }

    @include tablet {
      left: 0;
      top: auto;
      right: auto;
      bottom: 0;
      max-height: 100vh;
      width: 100%;
      overflow-y: auto;
    }

    @include mobile {
      left: 0;
      top: 0;
      right: auto;
      bottom: auto;
      height: 100vh;
    }
  }

  & .logo {
    margin-bottom: 2rem;
    font-size: 2rem;

    & > img {
      width: 4rem;
    }
  }

  &__form-group {
    height: 7rem;
    width: 100%;

    @include tablet {
      height: 8rem;
    }

    &.password {
      height: 9rem;

      @include tablet {
        height: 11rem;
      }

      @include mobile {
        height: 13rem;
      }
    }
  }

  &__input {
    height: auto;
    padding: 1.2rem 1rem;
    font-size: large;
    line-height: normal;
  }

  &__forgot-password-button {
    font-size: medium;
    color: var(--secondary-blue);
    cursor: pointer;
  }

  &__submit-button {
    width: 100%;
    margin-bottom: 1.2rem;
    padding: 1.2rem 0;
    font-size: large;
    color: var(--secondary-blue);
    border-color: var(--secondary-blue);
    background-color: transparent;
  }

  &__small-text {
    text-align: center;

    @include tablet {
      margin-bottom: 5rem;
    }
  }

  &__owner {
    position: absolute;
    bottom: 1rem;
    font-size: smaller;
    color: black;

    @media screen and (min-width: 1024px) and (max-width: 1280px) {
      bottom: 0.8rem;
    }
  }
}
