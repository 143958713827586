.invalidDiploma {
  &__title {
    color: var(--main-red);
  }

  &__text {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}
