@import "../../../assets/styles/mixins";

.file-card {
  position: relative;
  height: 32rem;
  padding: 2rem;
  padding-top: 4rem;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  box-shadow: 0 0 1.5rem 0 rgba(0, 0, 0, 0.1);

  &__header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 0.3rem 0;
    border-radius: 0.5rem 0.5rem 0 0;
    text-align: center;
    color: white;
    background-color: var(--main-green);

    &_error {
      background-color: #ef0808;
    }
  }

  &__props-wrapper {
    display: flex;
    flex-direction: column;
  }

  &__prop {
    padding-bottom: 0.7rem;
    color: #576275;

    &:not(:last-child) {
      border-bottom: 1px solid rgba(108, 123, 138, 0.17);
    }

    & > span {
      color: black;
    }
  }

  &__link {
    padding: 0.5rem 1rem;
    border-color: white;
    border-radius: 0.3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    color: var(--secondary-blue);
    background-color: transparent;
    border: 0.1rem solid var(--secondary-blue);
    transition: all linear 0.2s;

    &:hover {
      color: var(--secondary-blue);
    }

    & > img {
      width: 2rem;
      margin: 0;
    }
  }

  &__error-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__icon-wrapper {
    height: 15rem;
    width: 15rem;
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0.2rem solid rgba(19, 107, 254, 0.15);
    border-radius: 100%;

    @include tablet {
      height: 10rem;
      width: 10rem;
    }

    @include mobile {
      height: 16rem;
      width: 16rem;
    }
  }

  &__icon {
    height: 7rem;
    width: 6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #eaeaea;

    @include tablet {
      height: 5rem;
      width: 4rem;
    }

    @include tablet {
      height: 8rem;
      width: 7rem;
    }

    & > img {
      width: 5rem;
    }
  }
}
