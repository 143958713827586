.student-password-form {
  display: flex;
  flex-direction: column;

  &__form-group {
    height: 7rem;
    display: flex;
    flex-direction: column;
  }

  &__input {
    height: auto;
    margin-right: 1rem;
    padding: 0.7rem 1rem;
    font-size: large;
    line-height: normal;
  }

  &__submit-button {
    padding: 1.2rem 0;
  }

  &__modal {
    & .modal-content {
      width: 100% !important;
      border-radius: 1rem;
    }

    &-content {
      padding: 2rem 2rem;
      display: flex;
      flex-direction: column;
    }

    &-header {
      margin-bottom: 2rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &-title {
      margin: 0;
      font-size: 1.5rem;
      color: var(--main-blue);
    }

    &-icon {
      width: 2rem;
    }

    &-button {
      padding: 0.7rem 0;
      font-size: 1.2rem;
    }
  }
}
