.share-modal-body {
  padding: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__link {
    margin-bottom: 2rem;
  }

  &__button {
    padding: 0.7rem 1.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--main-blue);
    border-radius: 0.3rem;
    color: white;
    background-color: var(--main-blue);
  }

  &__after-copy-text {
    position: absolute;
    bottom: 1rem;
  }
}
