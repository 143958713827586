.confirm-modal {
  padding: 2rem 3rem;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 3rem;

    & > img {
      width: 2rem;
    }

    & > p {
      margin: 0;
      font-size: 1.1rem;
      color: var(--secondary-blue);
    }
  }

  &__content {
    margin-bottom: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    & > p {
      margin: 0;
    }
  }

  &__buttons {
    display: flex;
    width: 100%;
    gap: 1rem;
  }

  &__button {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 4rem;
  }
}