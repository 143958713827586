@import "../../../assets/styles/mixins";

.slideButton {
  background: transparent;
  height: 2rem;
  width: 2rem;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  border: solid 1px #3c7cf6;
  border-radius: 9999px;
  cursor: pointer;

  @include mobile {
    height: 3.2rem;
    width: 3.2rem;
  }

  &.prev {
    margin-right: 0.5rem;
    transform: rotate(180deg);

    @include mobile {
      margin-right: 1rem;
    }
  }

  & > img {
    width: 0.5rem;

    @include mobile {
      width: 1rem;
    }
  }

  &.disabled {
    border-color: rgba(108, 123, 138, 0.17);
    pointer-events: none;
  }
}
