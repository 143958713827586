@import "../../../assets/styles/mixins";

.instruction {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  border-radius: 2rem;
  background-color: white;
  box-shadow: 0px 30px 120px rgba(3, 6, 33, 0.02);
  //   background-image: url("../../../assets/images/main-page/instruction-background.png");
  //   background-position: top;
  //   background-repeat: no-repeat;
  //   background-size: cover;

  @include mobile {
    display: block;
    background-color: transparent;
  }

  &__step {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;

    @include tablet {
      padding: 1.5rem;
    }

    @include mobile {
      padding: 3rem 0;
    }
  }

  &__step-number {
    height: 3rem;
    width: 3rem;
    margin-bottom: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 3px solid rgba(60, 124, 246, 0.1);
    border-radius: 9999px;
    font-size: 1.3rem;
    color: var(--main-blue);

    @include mobile {
      margin-bottom: 3rem;
      height: 3.5rem;
      width: 3.5rem;
      font-size: 1.7rem;
    }
  }

  &__step-title {
    @include tablet {
      margin-bottom: 2rem;
      font-size: 1.4rem;
      font-weight: 700;
    }

    @include mobile {
      margin-bottom: 1.5rem;
      font-size: 1.7rem;
      font-weight: 700;
    }
  }

  &__step-text {
    text-align: center;

    @include mobile {
      font-size: 1.25rem;
    }
  }

  &__image-wrapper {
    height: 8rem;
    width: 8rem;
    margin-bottom: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 3px solid rgba(60, 124, 246, 0.1);
    border-radius: 9999px;

    @include tablet {
      height: 6rem;
      width: 6rem;
    }

    @include mobile {
      height: 8rem;
      width: 8rem;
    }

    & > img {
      width: 4rem;

      @include tablet {
        width: 3rem;
      }

      @include mobile {
        width: 4rem;
      }
    }
  }
}
