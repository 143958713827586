@import "../../../assets/styles/mixins";

.verify-two-factor-form {
  width: 100%;
  display: flex;
  flex-direction: column;

  &__information {
    margin-bottom: 2rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  &__instruction {
    & > h1 {
      font-size: 2rem;
      color: var(--main-blue);
    }

    & > p {
      font-size: medium;
      color: #757575;
    }
  }

  &__qr {
    & > img {
      width: 100%;
    }
  }

  &__form-group {
    height: 7rem;
    width: 100%;

    @include tablet {
      height: 8rem;
    }
  }

  &__input {
    height: auto;
    padding: 1.2rem 1rem;
    font-size: large;
    line-height: normal;
  }

  &__submit-button {
    width: 100%;
    margin-bottom: 1.2rem;
    padding: 1.2rem 0;
    font-size: large;
    color: var(--secondary-blue);
    border-color: var(--secondary-blue);
    background-color: transparent;
  }
}
