@import "../../../assets/styles/mixins";

.mainPageSection {
  position: relative;
  z-index: 1;
  padding: 3rem 0;

  @include tablet {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  @include mobile {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  &__name {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    font-size: small;
    font-weight: 500;
    letter-spacing: 0.05rem;
    text-transform: uppercase;
    color: var(--main-blue);

    @include mobile {
      display: grid;
      grid-template-columns: 1fr 3fr;
      font-size: larger;
    }
  }

  &__icon {
    height: 2.3rem;
    width: 2.3rem;
    margin-right: 1rem;
    border-radius: 9999px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(60, 124, 246, 0.1);

    @include mobile {
      height: 5rem;
      width: 5rem;
      margin: 0;
    }

    & > img {
      width: 65%;
    }
  }

  &__title {
    width: 44rem;
    font-weight: 500;
    font-size: 3rem;

    @include mobile {
      width: auto;
      font-size: 2.5rem;
      font-weight: 600;
    }
  }

  &__subtitle {
    margin-top: 1rem;
    margin-bottom: 3rem;
    font-size: 1rem;
    color: #576275;

    @include mobile {
      font-size: larger;
    }
  }
}
