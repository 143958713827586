.decline-modal {
  &__wrapper {
    padding: 2rem 3rem;
  }

  &__header {
    margin-bottom: 3rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__body {
    margin-bottom: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
  }

  &__field {
    display: flex;
    align-items: center;
    margin-bottom: 0.8rem;

    p {
      margin: 0 0 0 1rem;
      padding: 0;
      font-size: 16px;
    }

    input {
      border-radius: 0.3rem;
      border: 0.5px #576275A1 solid;
      padding: 1rem;
      width: 100%;

      &::placeholder {
        color: #576275A1;
      }
    }
  }

  &__dropdown {
    width: 100%;
    position: relative;
  }

  &__dropdown-toggle {
    background: transparent !important;
    color: #576275A1 !important;
    padding: 1rem 2rem !important;
    width: 100% !important;
    text-align: left;
    justify-content: space-between;
    display: flex;
    align-items: center;

    &:hover {
      background: transparent;
      color: #576275A1;
      transform: translate(0, 0);
    }

    img {
      filter: invert(0.3);
    }
  }

  &__dropdown-menu {
    width: 100%;
  }

  &__dropdown-item {
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__error {
    color: red;
    margin: 0;
  }

  &__button {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 0 !important;
  }
}