.diploma-buttons {
  margin-bottom: 2.5rem;

  &__wrapper {
    display: flex;
    align-items: center;
    gap: 1.6rem;
  }

  &__button {
    display: flex;
    align-items: center;
    border: none;
    border-radius: 0.3rem;
    color: white;
    padding: 1rem 1.6rem;
    gap: 3rem;

    &_approve {
      background-color:#2ED573;
    }

    &_decline {
      background: #FF4757;
    }
  }

  &__button-icon {
    background-color: rgba(255, 255, 255, 0.2);
    width: 1.5rem;
    aspect-ratio: 1/1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.3rem;
    padding: 0.4rem;
  }
}