@import "../../assets/styles/mixins";

.main-footer {
  padding: 1.5rem 0;
  background-color: #080341;

  @include mobile {
    padding: 3rem 0;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
  }

  &__navigation-wrapper {
    display: grid;
    grid-template-columns: 0.5fr 2fr 0.5fr;
    align-items: center;

    @include tablet {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
    }

    @include mobile {
      margin-bottom: 3rem;
      display: flex;
      flex-direction: column;
    }

    & .main-footer__navigation {
      &_mobile {
        @include mobile {
          order: 3;
        }

        @media screen and (min-width: 648px) {
          display: none;
        }
      }

      @include tablet {
        display: flex;
        order: 3;
        grid-column: 1 / 3;
        align-self: flex-end;
      }

      @include mobile {
        display: none;
      }
    }
  }

  &__navigation {
    display: flex;
    align-items: center;

    & > a {
      background: transparent;
      border: none;
      cursor: pointer;
      padding: 0;
      color: white;
      text-decoration: none;

      &:not(:last-child) {
        margin-right: 1.5rem;
      }
    }
  }

  & .logo {
    @include tablet {
      order: 1;
    }

    @include mobile {
      margin-bottom: 3rem;
    }
  }

  &__button-wrapper {
    justify-self: flex-end;

    @include tablet {
      order: 2;
    }

    @include mobile {
      width: 100%;
    }
  }

  &__button {
    @include mobile {
      width: 100%;
      margin: 0;
      margin-bottom: 2rem;
      padding: 1.5rem 0 !important;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    & > img {
      margin-right: 0.5rem;

      @include mobile {
        width: 1.3rem;
        margin-right: 0.7rem;
      }
    }

    & > p {
      margin: 0;
      line-height: normal;

      @include mobile {
        font-size: 1.2rem;
      }
    }
  }

  &__divider {
    height: 2px;
    width: 100%;
    margin: 2rem 0;
    background-color: rgba(255, 255, 255, 0.1);

    @include mobile {
      display: none;
    }
  }

  &__text-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;

    @include mobile {
      flex-direction: column;
      align-items: flex-start;
    }

    & > p:first-child {
      width: 60rem;
      color: rgba(255, 255, 255, 0.5);

      @include tablet {
        width: 41rem;
      }

      @include mobile {
        width: auto;
        margin-bottom: 2rem;
      }
    }
  }
}
