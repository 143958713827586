@import "../../assets/styles/mixins";

.verification-page {
  padding-top: 3rem;

  &__load-files-section {
    margin-bottom: 5rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    @include tablet {
      display: flex;
      flex-direction: column;
    }
  }

  &__information {
    @include tablet {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &__name {
    margin-top: 2.5rem;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    font-size: small;
    font-weight: 500;
    letter-spacing: 0.05rem;
    text-transform: uppercase;
    color: var(--main-blue);

    @include tablet {
      justify-content: center;
    }

    @include mobile {
      display: grid;
      grid-template-columns: 1fr 3fr;
      font-size: larger;
    }
  }

  &__icon {
    height: 2.3rem;
    width: 2.3rem;
    margin-right: 1rem;
    border-radius: 9999px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(60, 124, 246, 0.1);

    @include mobile {
      height: 4rem;
      width: 4rem;
      margin: 0;
    }

    & > img {
      width: 65%;
    }
  }

  &__title {
    width: 30rem;
    font-weight: 500;
    font-size: 3rem;

    @include tablet {
      width: auto;
      padding: 0 6rem;
      text-align: center;
    }

    @include mobile {
      padding: 0 0.5rem;
      font-size: 2.5rem;
      font-weight: 600;
    }
  }

  &__subtitle {
    margin-top: 1rem;
    margin-bottom: 3rem;
    font-size: smaller;
    color: #576275;

    @include tablet {
      margin-bottom: 2rem;
      font-size: medium;
      text-align: center;
    }

    @include mobile {
      font-size: larger;
    }
  }

  &__button {
    width: 12rem;
    padding: 1rem 0 !important;
    justify-content: center;

    &:first-child {
      margin-right: 1rem;
    }

    @include tablet {
      margin-bottom: 2rem;
    }

    @include mobile {
      width: 100%;
    }
  }

  &__drag-n-drop {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 0.15rem solid var(--main-blue);
    border-radius: 0.5rem;

    & > img {
      width: 5rem;
      margin-bottom: 2rem;
    }
  }

  &__result-section {
    & > h2 {
      margin-bottom: 2.5rem;
    }
  }
}
