@import "../../../assets/styles/mixins";

.set-two-factor-form {
  width: 100%;
  display: flex;
  flex-direction: column;

  &__information {
    margin-bottom: 1rem;
    display: grid;
    grid-template-columns: 1.2fr 1fr;

    @media screen and (min-width: 1024px) and (max-width: 1280px) {
      grid-template-columns: 1.8fr 1fr;
    }

    @include tablet {
      grid-template-columns: 2fr 1fr;
    }

    @include mobile {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &__instruction {
    & > h1 {
      font-size: 2rem;
      color: var(--main-blue);

      @media screen and (min-width: 1024px) and (max-width: 1280px) {
        font-size: 1.8rem;
      }
    }

    & > p {
      font-size: medium;
      color: #757575;

      @media screen and (min-width: 1024px) and (max-width: 1280px) {
        font-size: 1.2rem;
      }
    }
  }

  &__qr {
    @include mobile {
      width: 40%;
    }

    & > img {
      width: 100%;
    }
  }

  &__form-group {
    height: 7rem;
    width: 100%;

    @media screen and (min-width: 1024px) and (max-width: 1280px) {
      height: 6.2rem;
    }

    @include tablet {
      height: 8rem;
    }
  }

  &__input {
    height: auto;
    padding: 1.2rem 1rem;
    font-size: large;
    line-height: normal;

    @media screen and (min-width: 1024px) and (max-width: 1280px) {
      font-size: 1rem;
    }
  }

  &__submit-button {
    width: 100%;
    margin-bottom: 1.2rem;
    padding: 1.2rem 0;
    font-size: large;
    color: var(--secondary-blue);
    border-color: var(--secondary-blue);
    background-color: transparent;
  }
}
