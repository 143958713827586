@import "../../assets/styles/mixins";

$player-radius: 1rem;
$big-play-button-size: 5rem;
$medium-play-button-size: 4rem;
$small-play-button-size: 3rem;

.video-js {
  border: 0.2rem solid white;
  border-radius: $player-radius;

  & > video {
    border-radius: $player-radius;
  }

  & .vjs-big-play-button {
    height: $big-play-button-size;
    width: $big-play-button-size;
    font-size: 1rem;
    line-height: $big-play-button-size;
    border-radius: 9999px;
    background-color: transparent;

    @include tablet {
      height: $medium-play-button-size;
      width: $medium-play-button-size;
      line-height: $medium-play-button-size;
    }

    @include mobile {
      height: $small-play-button-size;
      width: $small-play-button-size;
      line-height: $small-play-button-size;
    }

    & .vjs-icon-placeholder:before {
      top: auto;
      left: auto;
      height: auto;
      width: auto;
      transform: translateX(-50%) translateY(-50%);
    }
  }

  & .vjs-control-bar {
    border-bottom-left-radius: $player-radius;
    border-bottom-right-radius: $player-radius;
  }
}

.vjs-big-play-centered .vjs-big-play-button {
  margin: 0;
  //   font-size: 1rem;
  left: 45%;
  transform: translateX(-50%) translateY(-50%);

  @include tablet {
    left: 43%;
  }

  @include mobile {
    left: 38%;
  }

  &::after {
    content: "Watch video";
    position: absolute;
    top: 0;
    left: 130%;
    width: max-content;
    display: block;
  }
}
