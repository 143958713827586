.custom-button {
  background: transparent;
  border: none;
  font-weight: normal;
  font-size: 0.9rem;
  line-height: 1.2rem;
  color: var(--secondary-blue);
  transition: all 0.15s ease-in-out;

  &_full {
    background-color: var(--secondary-blue);
    color: var(--main-white);
    padding: 0.9rem 1.8rem;
    border-radius: 0.25rem;
  }

  // &:hover {
  //   transform: translateY(-5%);
  // }

  &:disabled {
    opacity: 0.7;
  }
}
