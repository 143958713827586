@import "../../../assets/styles/mixins";

.contact-form {
  height: 45rem;
  padding: 4rem;
  border-radius: 1rem;
  box-shadow: 0px 25px 120px rgba(98, 111, 132, 0.03);
  background-color: white;

  @include mobile {
    height: auto;
    padding: 2rem;
  }

  &__title {
    margin-bottom: 1.5rem;
    font-size: 2.5rem;

    & span {
      text-decoration: underline;
      text-underline-offset: 3px;
      color: var(--main-blue);
    }
  }

  &__text {
    margin-bottom: 4rem;
    font-size: medium;
    color: #576275;

    @include mobile {
      margin-bottom: 2rem;
    }
  }

  &__row {
    margin-bottom: 1rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 1rem;

    @include mobile {
      display: block;
    }
  }

  &__col {
    display: flex;
    flex-direction: column;

    @include mobile {
      margin-bottom: 1rem;
    }
  }

  &__label {
    @include mobile {
      font-size: 1.3rem;
    }
  }

  &__input {
    padding: 1.5rem 1rem;

    @include mobile {
      padding: 2rem 1.5rem;
      font-size: 1.3rem;
    }
  }

  & small {
    @include mobile {
      font-size: 1rem;
    }
  }

  & textarea {
    height: 10rem !important;
    padding: 1rem;
    resize: none;

    @include mobile {
      margin-bottom: 1.5rem;
      padding: 1.5rem;
      font-size: 1.3rem;
    }
  }

  &__submit-button {
    width: 100%;
    padding: 1rem 0;
    background-color: var(--secondary-blue);

    @include mobile {
      padding: 1.5rem;
      font-size: large;
    }
  }
}
