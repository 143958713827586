.uploaded-file {
  position: relative;
  height: 9rem;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  word-break: break-all;

  &__icon {
    height: 6rem;
    margin-bottom: 0.5rem;
    padding: 0.5rem 0.15rem;
    display: flex;
    align-items: center;
    border: 1px solid #eaeaea;

    & > img {
      width: 100%;
    }
  }

  &__delete-button {
    position: absolute;
    top: 0;
    right: 0;
    height: max-content;
    width: max-content;
    display: block;
    background-color: transparent;
    border: none;
    transform: translateX(50%) translateY(-50%);
    cursor: pointer;

    & > img {
      width: 1rem;
    }
  }
}
