@import "../../../assets/styles/mixins";

.files-list {
  margin-bottom: 7rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;

  @include mobile {
    display: none;
  }

  &_mobile {
    display: none;
    margin: 0 -2rem;
    margin-bottom: 5rem;
    padding: 0 2.5rem;

    @include mobile {
      display: block;
    }
  }

  &__loading {
    height: 32rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem;
    box-shadow: 0 0 1.5rem 0 rgba(0, 0, 0, 0.1);
    background-color: white;
  }
}
