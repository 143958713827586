.change-language {
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
  color: white;
  height: fit-content;

  text-transform: uppercase;
  position: relative;
  justify-content: center;

  button {
    background: transparent;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: white;
    height: fit-content;

    text-transform: uppercase;
    font-weight: 500;
  }

  &_black {
    color: black;
    button {
      color: black;
    }

    img {
      filter: invert(1);
    }
  }

  &__main-button {
    img {
      margin-left: 0.2rem;
    }

    p {
      margin: 0;
      padding: 0;
      font-weight: 500;
    }
  }

  &__languages {
    position: absolute;
    top: 100%;
    height: 0;
    overflow: hidden;

    &_open {
      height: fit-content;
    }

    span {
      cursor: pointer;
    }
  }
}