@font-face {
  font-family: "Value Sans Pro";
  src: url("./assets/fonts/ValueSansPro-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Value Sans Pro";
  src: url("./assets/fonts/ValueSansPro-Medium.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Value Sans Pro";
  src: url("./assets/fonts/ValueSansPro-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Manrope";
  src: url("./assets/fonts/Manrope-ExtraLight.ttf") format("truetype");
  font-weight: 200;
}

@font-face {
  font-family: "Manrope";
  src: url("./assets/fonts/Manrope-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Manrope";
  src: url("./assets/fonts/Manrope-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Manrope";
  src: url("./assets/fonts/Manrope-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Manrope";
  src: url("./assets/fonts/Manrope-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Manrope";
  src: url("./assets/fonts/Manrope-Bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Manrope";
  src: url("./assets/fonts/Manrope-ExtraBold.ttf") format("truetype");
  font-weight: 800;
}

// videos js city theme
@import url("https://unpkg.com/video.js@7/dist/video-js.min.css");
@import url("https://unpkg.com/@videojs/themes@1/dist/city/index.css");

@import "./assets/styles/reset.scss";

@import "bootstrap/scss/bootstrap.scss";
@import "./assets/plugins/nucleo/css/nucleo.css";
@import "@fortawesome/fontawesome-free/css/all.min.css";
@import "react-toastify/dist/ReactToastify.css";

@import "./assets/styles/argon-dashboard-react.scss";

@import "./assets/styles/mixins";
@import "./assets/styles/variables.scss";
@import "./assets/styles/buttons";
@import "./assets/styles/titles";
@import "./assets/styles/input";

html {
  font-size: 16px;

  @include tablet {
    font-size: 1.8vw;
  }

  @include mobile {
    font-size: 3vw;
  }

  @media (min-width: 1023px) {
    font-size: 1vw;
  }

  @media (min-width: 1800px) {
    font-size: 18px;
  }
}

body {
  margin: 0;
  font-family: Open Sans, sans-serif;
  font-weight: 400;
  line-height: 1.5;
  color: #525f7f;
  text-align: left;
  background-color: #f8f9fe;
}

.App {
  min-height: 100vh;
}

.main-app {
  font-family: "Manrope", "Value Sans Pro", sans-serif;
  background-color: #fdfdfd;
  color: #2c2c2c;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .container {
    max-width: unset;
    width: Min(1400px, 75vw);
    margin: 0 auto;

    // @include container {
    //   width: 1000px;
    // }

    @include tablet {
      padding: 0 25px;
      width: 100%;
      max-width: none;
    }
  }
}

.grecaptcha-badge {
  visibility: hidden;
}
.loader {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
