@import "../../../assets/styles/mixins";

.whySection {
  position: relative;
  margin: 7rem 0;

  &__background {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100vw;
    transform: translate(-50%, -50%);
    max-height: 110rem;

    @include tablet {
      transform: scale(1.7) translate(-10%, -30%);
    }

    @include mobile {
      transform: scale(5) translateY(-3%);
    }
  }

  &__wrapper {
    position: relative;
  }

  &__icon {
    height: 2.3rem;
    width: 2.3rem;
    margin-right: 1rem;
    border-radius: 9999px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.1);

    @include tablet {
      height: 3.3rem;
      width: 3.3rem;
    }

    & > img {
      width: 65%;
    }
  }

  &__name {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    font-size: small;
    font-weight: 500;
    letter-spacing: 0.05rem;
    text-transform: uppercase;
    color: white;

    @include tablet {
      font-size: large;
    }
  }

  &__title {
    // margin-bottom: 5rem;
    font-weight: 300;
    font-size: 3rem;
    color: white;

    @include tablet {
      font-size: 2.5rem;
    }

    &-bold {
      font-weight: 500;

      span {
        text-decoration: underline;
        text-decoration-thickness: 0.05rem;
        text-underline-offset: 0.3rem;
      }
    }
  }
}

.why-list {
  display: grid;
  grid-template-rows: repeat(3, auto);
  margin-top: 1.75rem !important;
}

.why-item {
  display: grid;
  grid-template-columns: 1fr 1.3fr;
  align-items: center;
  color: white;
  border-bottom: 0.05rem solid rgba(255, 255, 255, 0.13);
  padding-bottom: 2rem;
  padding-top: 2rem;

  @include mobile {
    height: auto;
    display: block;
    padding-bottom: 1.5rem;
  }

  &__title-wrapper {
    display: flex;
    align-items: center;
  }

  &__marker {
    height: 0.5rem;
    width: 0.5rem;
    margin-right: 1rem;
    border-radius: 9999px;
    background-color: white;

    @include tablet {
      height: 0.7rem;
      width: 0.7rem;
      margin-right: 1.5rem;
    }

    @include mobile {
      display: none;
    }
  }

  &__title {
    margin: 0;
    font-size: 3rem;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    color: white;

    @include mobile {
      margin-bottom: 2rem;
      font-size: 4rem;
    }
  }

  &__subtitle {
    font-size: large;
    color: white;

    @include tablet {
      font-size: 1rem;
    }

    @include mobile {
      margin-bottom: 1rem;
      font-size: 1.5rem;
    }
  }

  &__text {
    width: 95%;
    margin: 0;
    font-size: medium;
    font-weight: 200;

    @include tablet {
      font-size: 0.7rem;
    }

    @include mobile {
      font-size: 1.3rem;
    }
  }
}
