@import "../../assets/styles/mixins";

.diploma {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;

  &__loader {
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__loader-indicator {
    width: 4rem;
    margin-right: 2rem;
  }

  &__loader-text {
    margin: 0;
    font-size: 1.5rem;
  }

  & p {
    margin: 0;
    font-weight: 400;
  }

  &__wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(108, 123, 138, 0.17);
    border-radius: 0.5rem;
    background-color: white;
    overflow: hidden;
  }

  &__header {
    padding: 2rem 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include mobile {
      flex-direction: column;
      justify-content: start;
      align-items: flex-start;
    }

    & p {
      font-size: medium;
      color: #576275;

      @include mobile {
        display: none;
      }
    }
  }

  &__logo {
    margin-bottom: 0.5rem;

    @include mobile {
      margin-bottom: 1.5rem;
    }

    & > img {
      width: 12rem;

      @include mobile {
        width: 15rem;
      }
    }
  }

  &__institute-logo {
    & > img {
      height: 4rem;
    }
  }

  &__student-name {
    padding: 2rem 3rem;
    border-bottom: 1px solid rgba(108, 123, 138, 0.17);

    @include mobile {
      padding: 1rem 3rem;
      border-bottom: none;
    }
  }

  &__title {
    font-size: 4rem;
  }

  &__subtitle {
    margin-bottom: 0.5rem;
    color: #576275;
  }

  &__content {
    padding: 2rem 3rem;
    display: flex;
    align-items: center;

    @include mobile {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__content-item {
    display: flex;
    flex-direction: column;

    &:not(:last-child) {
      margin-right: 5rem;

      @include mobile {
        margin-right: 0;
        margin-bottom: 1rem;
      }
    }
  }

  &__footer {
    padding: 2rem 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--main-blue);

    @include mobile {
      flex-direction: column;
      justify-content: start;
      align-items: flex-start;
    }
  }

  &__files-wrapper {
    display: flex;
    flex-direction: column;

    @include mobile {
      width: 100%;
    }

    & > p {
      color: white;
    }
  }

  &__files {
    margin-top: 0.5rem;
    display: flex;

    @include mobile {
      flex-direction: column;
      margin-bottom: 3rem;
    }
  }

  &__files-item {
    padding: 0.8rem 1.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid white;
    border-radius: 0.2rem;
    color: white;
    cursor: pointer;

    &:not(:last-child) {
      margin-right: 0.7rem;

      @include mobile {
        margin-right: 0;
        margin-bottom: 0.7rem;
      }
    }

    & > img {
      width: 2rem;
      margin-right: 1rem;
    }
  }

  &__qr {
    cursor: pointer;

    & > img {
      width: 7rem;
      border-radius: 0.2rem;
    }
  }

  &__modal {
    & > .modal-content {
      max-width: 31.25rem;
      background: transparent;
      border: none;
    }

    & .react-pdf__Page__annotations {
      display: none;
    }

    @media screen and (min-width: 1000px) {
      max-width: none;
      margin: 0;
      padding: 1rem 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.content {
  height: 100%;
  width: 77%;

  @include tablet {
    height: auto;
    width: 100%;
  }

  &__wrapper {
    border: 0.05rem solid #2c2c2c;
    background-image: url("../../assets/images/diploma-bg.svg");
    background-size: cover;
    background-color: rgba(245, 245, 236, 0.29);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 1.8rem;
    padding-bottom: 1.8rem;
    padding-left: 3.8rem;
    padding-right: 7rem;

    @include tablet {
      flex-direction: column;
      border: none;
      padding: 16px 12px 24px 12px;
    }
  }

  &__descr {
    margin-right: 0.5rem;
    width: 65%;

    @include tablet {
      margin-right: 0;
      width: auto;
      text-align: center;
    }
  }

  &__title {
    font-size: 1.4rem;
    line-height: 1.8rem;
    font-weight: 400;
    color: var(--main-black);
    position: relative;
    padding-bottom: 0.2rem;
    margin-bottom: 0;

    @include tablet {
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      padding-bottom: 12px;
    }

    &::before {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      height: 0.1rem;
      width: 12.5rem;
      background-color: var(--main-red);

      @include tablet {
        height: 2px;
        width: 120px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  &__main-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include tablet {
      justify-content: center;
    }
  }

  &__main-logo {
    width: 6rem;
    height: 6rem;
    display: flex;
    justify-content: center;
    align-items: center;

    @include tablet {
      display: none;
    }
  }

  &__name {
    margin-top: 1.2rem;
    margin-bottom: 0.6rem;
    font-weight: 400;
    font-size: 2.4rem;
    line-height: 3rem;
    color: var(--main-red);

    @include tablet {
      margin-top: 24px;
      margin-bottom: 6px;
      font-size: 36px;
      line-height: 48px;
    }
  }

  &__subtitle {
    margin-top: 0.3rem;
    margin-bottom: 0;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.8rem;
    color: var(--main-black);

    @include tablet {
      margin-top: 0;
      margin-bottom: 12px;
      font-size: 28px;
      line-height: 36px;
    }
  }

  &__date {
    margin-top: 0.3rem;
    font-size: 0.9rem;
    line-height: 1.2rem;
    font-weight: 400;

    @include tablet {
      font-size: 18px;
      line-height: 24px;
      margin-top: 0;
    }

    & > span {
      color: var(--main-red);
    }
  }

  &__info {
    margin-top: 1.8rem;

    @include tablet {
      margin-top: 36px;
    }

    & > p {
      font-style: normal;
      font-weight: 400;
      font-size: 1.2rem;
      line-height: 1.4rem;
      margin: 0;
      margin-top: 0.6rem;

      @include tablet {
        font-size: 18px;
        line-height: 24px;
        margin-top: 24px;
      }
    }

    & > h5 {
      margin-top: 0.3rem;
      margin-bottom: 0;
      font-style: normal;
      font-weight: 400;
      font-size: 0.9rem;
      line-height: 1.2rem;
      color: var(--main-red);
      word-break: break-all;

      @include tablet {
        margin-top: 4px;
        font-size: 16px;
        line-height: 18px;
      }
    }
  }

  &__qr-wrapper {
    margin-top: 1.2rem;
    cursor: pointer;
    display: inline-block;

    @include tablet {
      display: none;
    }
  }

  &__qr-img {
    width: 6.2rem;

    @include tablet {
      width: 124px;
    }
  }

  &__logo {
    max-width: 100%;
    max-height: 100%;
    opacity: 0.5;

    @include tablet {
      width: 95px;
    }
  }

  &__files {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-self: stretch;

    @include tablet {
      margin-top: 36px;
      flex-direction: row;
    }
  }

  &__files-item {
    width: 10rem;
    height: 10rem;
    background: #fdfdfd;
    border: 0.04rem dashed #4c73ff;
    box-sizing: border-box;
    border-radius: 0.8rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: 400;
    font-size: 1.45rem;
    line-height: 1.95rem;
    // opacity: 0.8;
    cursor: pointer;

    @include tablet {
      width: 141px;
      height: 141px;
      border-radius: 16px;
    }

    & > img {
      width: 4rem;
      margin-bottom: 0.5rem;
    }
  }

  &__mobile-footer {
    margin-top: 36px;
    display: none;
    width: 100%;
    justify-content: space-around;
    align-items: center;

    @include tablet {
      display: flex;
    }
  }
}

.react-pdf__Document {
  position: relative;
  width: 60vw;
  transform: translateX(-25%);

  &:hover {
    .page-controls {
      opacity: 1;
    }
  }

  @media screen and (max-width: 1023px) {
    transform: translateX(0);
  }

  @media screen and (max-width: 647px) {
    width: auto;
  }
}

.react-pdf__Page {
  & canvas {
    width: 100% !important;
    height: auto !important;
  }
}

.page-controls {
  position: absolute;
  bottom: 5%;
  left: 50%;
  background: white;
  opacity: 0;
  transform: translateX(-50%);
  transition: opacity ease-in-out 0.2s;
  border: 0.05rem solid gray;
  display: flex;
  align-items: center;
  box-shadow: 0px 0.5rem 1rem rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;

  @include tablet {
    opacity: 1;
  }

  & > span {
    font-family: "Value Sans Pro";
    font-size: 1rem;
    padding: 0 0.5rem;
  }

  & > button {
    width: 44px;
    height: 44px;
    background: white;
    border: 0;
    font-family: "Value Sans Pro";
    font-size: 1.5rem;
    border-radius: 0.5rem;

    &:enabled {
      &:hover {
        cursor: pointer;
        background-color: #e6e6e6;
      }
    }

    &:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

.block {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.text {
  font-size: 18px;
}