@import "../../assets/styles/mixins";

.student-page {
  min-height: 30rem;
  overflow: visible;

  &__content {
    transform: translateY(-10rem);

    @media screen and (min-width: 1024px) and (max-width: 1200px) {
      transform: translateY(-20rem);
    }

    @include mobile {
      display: flex;
      flex-direction: column;
    }
  }

  &__toggle-wrapper {
    margin-bottom: 2rem;

    @include mobile {
      display: flex;
      flex-direction: column;
    }
  }

  &__toggle {
    padding: 0.3rem 0.9rem;
    font-size: 1rem;
    border: 1px solid #a5a5a5;
    color: white;
    background-color: transparent;

    @include mobile {
      width: 100%;
      padding: 1rem 0;
      margin-bottom: 1rem;
    }

    &.left {
      margin-right: 0.5rem;
      padding-left: 1.2rem;
      border-radius: 9999px 150rem 150rem 9999px;

      @include mobile {
        margin-right: 0;
        padding-left: 0;
        border-radius: 0.5rem;
      }
    }

    &.right {
      padding-right: 1.2rem;
      border-radius: 150rem 9999px 9999px 150rem;

      @include mobile {
        padding-right: 0;
        border-radius: 0.5rem;
      }
    }

    &.active {
      background-color: #136bfe;
    }
  }
}
