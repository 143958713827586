@import "../../../assets/styles/mixins";

.student-settings {
  flex-grow: 1;
  padding-top: 3rem;
  padding-bottom: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../../../assets/images/student-page/settings-background.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  &__content {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(2, 1fr);

    @include container {
      display: flex;
      justify-content: center;
      align-items: stretch;
    }

    @include tablet {
      display: flex;
      flex-direction: column;
      align-items: stretch;
    }
  }

  &__text-wrapper {
    @include container {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }

    @include tablet {
      margin-bottom: 2rem;
      padding: 0 7rem;
    }

    @include mobile {
      padding: 0;
    }
  }

  &__form-wrapper {
    @include container {
      width: 45rem;
    }

    @include tablet {
      width: auto;
      padding: 0 7rem;
    }

    @include mobile {
      padding: 0;
    }
  }
}
