@import "../../assets/styles/mixins";

.searchbar {
  position: relative;

  &_header {
    @include mobile {
      display: none;
    }

    & > input {
      padding: 1.05rem 1.2rem;
      border: 1px solid #f1f1f1;

      @include tablet {
        font-size: medium;
      }
    }
  }

  &__input {
    width: 100%;
    border: 0.05rem solid rgba(44, 44, 44, 0.7);
    border-radius: 0.25rem;
    font-weight: 400;
    font-size: 0.8rem;
    line-height: 0.8rem;
    padding: 1.5rem 7.5rem 1.5rem 1.2rem;
    background-color: #fff;

    @include tablet {
      font-size: 1.5rem;
    }

    @include mobile {
      padding: 2rem 1.2rem;
    }

    &_student {
      border: 1px solid rgba(255, 255, 255, 0.36) !important;
      color: white;
      background-color: transparent;
    }
  }

  &__btn {
    position: absolute;
    right: 0.5rem;
    top: 50%;
    transform: translateY(-50%);
    padding: 0.9rem 1.3rem;
    display: flex;
    align-items: center;
    font-size: 0.9rem;
    line-height: 1.1rem;

    @include tablet {
      padding: 1.5rem 1.6rem;
      font-size: 1.5rem;
    }

    @include mobile {
      top: 160%;
      left: 0;
      right: 0;
      padding: 2rem 0;
      display: block;
    }

    & > img {
      width: 0.85rem;
      margin-left: 0.5rem;

      @include tablet {
        width: 1.3rem;
      }
    }

    // &:hover {
    //   transform: translateY(-50%) scale(1.05);
    // }

    &_header {
      position: absolute;
      right: 0.5rem;
      top: 50%;
      padding: 0.7rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0.5rem;
      transform: translateY(-50%);

      & > img {
        margin: 0;

        @include tablet {
          width: 1.3rem;
        }
      }
    }

    &_student {
      background-color: white;
    }
  }

  // &__qr {
  //   position: absolute;
  //   right: 8rem;
  //   top: 50%;
  //   transform: translateY(-50%);
  //   width: 1.4rem;
  //   height: 1.4rem;
  //   cursor: pointer;
  //   transition: all 0.15s ease;

  //   @include tablet {
  //     display: none;
  //   }

  //   // &:hover {
  //   //   transform: translateY(-55%);
  //   // }
  // }

  // &__searchpage-qr {
  //   display: block;
  //   margin: 0 auto;
  //   margin-top: 1.2rem;
  //   padding: 0;
  //   font-family: "Roboto", sans-serif;
  //   background: transparent;
  //   border: none;
  //   opacity: 0.6;
  //   transition: all 0.15s ease-in-out;

  //   @include tablet {
  //     display: flex;
  //     flex-direction: column;
  //     align-items: center;
  //     margin-top: 24px;
  //   }

  //   & > img {
  //     width: 2rem;
  //     margin-right: 0.4rem;

  //     @include tablet {
  //       width: 44px;
  //       margin-right: 0;
  //     }
  //   }

  //   & > span {
  //     font-weight: 400;
  //     font-size: 0.8rem;
  //     line-height: 1rem;
  //     color: var(--main-black);

  //     @include tablet {
  //       margin-top: 12px;
  //       font-size: 18px;
  //       line-height: 24px;
  //     }
  //   }

  //   // &:hover {
  //   //   transform: translateY(-5%);
  //   // }
  // }

  &__error {
    position: absolute;
    left: 0;
    top: 110%;
    width: max-content;
    font-size: 1rem;

    @include mobile {
      // font-size: 0.8rem;
      top: -2rem;
      width: auto;
      height: 2rem;
      display: flex;
      align-items: flex-end;
    }
  }
}
