@import "../../../assets/styles/mixins";

.user-dropdown {
  position: relative;

  &__button {
    min-width: 7rem;
    padding: 0.5rem 0.7rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 1.5rem;

    @include tablet {
      height: 3.5rem;
      width: 3.5rem;
      min-width: auto;
      margin-right: 1rem;
      padding: 0;
      border-radius: 9999px;
    }

    &.primary {
      border: 0.01rem solid #e0e0e0;
      background-color: var(--main-white);
    }

    &.secondary {
      border: 0.01rem solid var(--main-white);
      background-color: transparent;
    }

    &.active {
      border-radius: 1.5rem 1.5rem 0 0;
      border-color: #e0e0e0;
      background-color: var(--main-white);
    }
  }

  &__avatar {
    width: 2rem;

    @include tablet {
      width: 100%;
    }
  }

  &__username {
    margin: 0 0.7rem;
    font-size: 1rem;

    @include tablet {
      display: none;
    }

    &.primary {
      color: var(--main-blue);
    }

    &.secondary {
      color: var(--main-white);
    }

    &.active {
      color: var(--main-blue);
    }
  }

  &__button-arrow {
    @include tablet {
      display: none;
    }

    &.rotate {
      transform: rotate(180deg);
    }
  }

  &__content {
    z-index: 1;
    position: absolute;
    top: 100%;
    width: 100%;
    padding: 0.2rem;
    display: flex;
    flex-direction: column;
    border: 0.01rem solid #e0e0e0;
    border-top: none;
    border-radius: 0 0 1.5rem 1.5rem;
    background-color: var(--main-white);

    @include tablet {
      right: 1rem;
      width: 10rem;
    }
  }

  &__link-wrapper {
    padding: 1rem 0.7rem;

    &:not(:last-child) {
      border-bottom: 0.01rem solid #e0e0e0;
    }
  }

  &__link {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--main-black);

    &:hover,
    &:active {
      color: var(--main-black);
    }
  }

  &__link-arrow-wrapper {
    padding: 0 0.45rem;
  }

  &__log-out-button {
    height: 100%;
    width: 100%;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none;
    color: #ef0808;
    background-color: transparent;

    &:hover {
      text-decoration: underline;
    }
  }

  &__log-out-icon {
    width: 1.3rem;
  }
}
