.transfer-document-form {
  padding: 3rem;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;

    & > img {
      width: 2rem;
    }

    & > h2 {
      margin: 0;
      font-size: 1.1rem;
      color: var(--secondary-blue);
      max-width: 15rem;
    }
  }

  &__content {
    margin-bottom: 1.5rem;
    & > p {
      margin: 0;
    }
  }

  &__input {
    display: flex;
    flex-direction: column;
    gap: 0.1rem;
    & > label {
      color: black;
    }

    & > input {
      padding: 1rem 1rem;
      border: var();
      border: 0.5px rgba(87, 98, 117, 0.4) solid;
      border-radius: 0.3rem;
    }

    & > input::placeholder {
      color: rgba(87, 98, 117, 0.4);
    }
  }

  &__buttons {
    display: flex;

    &>button {
      width: 100%;
      height: 3rem;
    }
  }

  &__submit-button {
    width: 100%;
    height: 3rem;
  }

}

.transfer-document-confirm {
  padding: 3rem;

  &__text {
    margin-bottom: 2rem;
    text-align: center;
  }

  &__buttons-wrapper {
    display: flex;
    justify-content: center;
  }

  &__button {
    padding: 0.7rem 1.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--main-blue);
    border-radius: 0.3rem;

    &.primary {
      color: white;
      background-color: var(--main-blue);
    }

    &.secondary {
      color: var(--main-blue);
      background-color: transparent;
    }

    &:not(:last-child) {
      margin-right: 2rem;
    }
  }
}
