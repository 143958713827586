@import "../../assets/styles/mixins";

.logo {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  color: white;

  &:hover {
    color: white;
    text-decoration: none;
  }

  @include mobile {
    font-size: 1.7rem;
  }

  &_black {
    color: black;

    &:hover {
      color: black;
    }
  }

  &_blue {
    color: var(--secondary-blue);

    &:hover {
      color: var(--secondary-blue);
    }
  }

  & > img {
    width: 3rem;
    margin-right: 0.5rem;

    @include mobile {
      width: 3.5rem;
      margin-right: 0.7rem;
    }
  }
}
