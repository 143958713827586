@import "../../assets/styles/mixins";

.main-header {
  height: 6rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include tablet {
    padding-top: 20px !important;
    padding-bottom: 16px !important;
    justify-content: center;
  }

  &__wrapper {
    border-bottom: 1px solid #e0e0e0;

    &_student {
      position: absolute;
      top: 0;
      width: 100%;
      border-bottom: 1px solid rgba(255, 255, 255, 0.15);
      background-color: rgba(241, 241, 241, 0.05);
      backdrop-filter: blur(30px);
    }
  }

  &__logo {
    width: 10rem;
  }

  &__search {
    flex-grow: 1;
    margin: 0 2rem;
  }

  &__button {
    background-color: rgba(19, 107, 254, 0.1) !important;
    border-color: rgba(19, 107, 254, 0.1) !important;

    &:hover {
      color: var(--secondary-blue) !important;
    }

    @include tablet {
      display: none !important;
    }
  }

  &__mobile-burger {
    display: none;
    height: 3.5rem;
    width: 3.5rem;
    border-radius: 0.5rem;
    background-color: var(--secondary-blue);
    border-color: var(--secondary-blue);

    & > img {
      height: 1.3rem;
      width: 1.3rem;
    }

    @include tablet {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__mobile-menu {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 99;
    transform: translateY(0);
    background-color: #fff;
    flex-direction: column;
    align-items: center;
    padding: 20px 15px;
    overflow-y: scroll;
    transition: transform 0.25s ease-in-out;

    @include tablet {
      display: flex;
    }

    &_hidden {
      transform: translateY(-100%);
    }

    & > h4 {
      margin-top: 26px;
      margin-bottom: 48px;
    }
  }

  &__mobile-close {
    width: 42px;
    border: none;
    background: transparent;
  }

  &__mobile-list {
    margin-top: 30px !important;
    list-style: none;

    & > li {
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 28px;
      text-align: center;
    }

    & a {
      color: var(--main-red);
      text-decoration: underline;
      display: block;
      padding: 18px 0;
    }
  }

  &__mobile-search {
    width: 100%;
    margin-top: 48px;
  }
}
