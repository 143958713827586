@import "../../../assets/styles/mixins";

.document-card {
  height: 45rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #e8ecf1;
  border-radius: 0.5rem;
  background-color: white;

  &__header {
    margin-bottom: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    text-transform: uppercase;
  }

  &__document-type {
    display: flex;
    align-items: center;
  }

  &__file-icon {
    height: 2.5rem;
    width: 2.5rem;
    margin-right: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem;
    background-color: rgba(19, 107, 254, 0.2);
    & > img {
      width: 1.5rem;
    }
  }

  &__institute-logo {
    & img {
      height: 3rem;
    }
  }

  &__props-wrapper {
    display: flex;
    flex-direction: column;
  }

  &__prop {
    padding-bottom: 0.7rem;
    color: #576275;

    &:not(:last-child) {
      border-bottom: 1px solid rgba(108, 123, 138, 0.17);
    }

    & > span {
      color: black;
    }
  }

  &__footer {
    display: flex;
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #136bfe;
    border-radius: 0.3rem;

    &:not(:last-child) {
      margin-right: 0.5rem;
    }

    &_link {
      flex-grow: 1;
      height: 3rem;
      padding: 0 0.7rem;
      justify-content: space-between;
      font-size: 0.9rem;
      color: white;
      background-color: #136bfe;

      &:hover {
        text-decoration: none;
        color: white;
      }

      & > img {
        width: 1.5rem;
      }
    }

    &_icon {
      height: 3rem;
      width: 3rem;
      background-color: transparent;

      & > img {
        width: 1.5rem;
      }
    }
  }
}
