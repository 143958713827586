.checkbox {
  border: 1px #576275A1 solid;
  border-radius: 0.3rem;
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  margin: 0;
  input {
    display: none;
  }

  &_checked {
    background-color: #136BFE;
    border: none;

    img {
      width: 70%;
      margin-left: 0.05rem;
    }
  }
}