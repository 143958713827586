@import "../../../assets/styles/mixins";

.login-form {
  width: 100%;
  display: flex;
  flex-direction: column;

  &__form-group {
    height: 7rem;
    width: 100%;

    @include tablet {
      height: 8rem;
    }
  }

  &__input {
    height: auto;
    padding: 1.2rem 1rem;
    font-size: large;
    line-height: normal;
  }

  &__forgot-password-button {
    align-self: flex-start;
    margin-bottom: 1rem;
    padding: 0;
    margin-top: 0.5rem;
    font-size: medium;
    border: none;
    color: var(--secondary-blue);
    background-color: transparent;
  }

  &__submit-button {
    width: 100%;
    margin-bottom: 1.2rem;
    padding: 1.2rem 0;
    font-size: large;
    color: var(--secondary-blue);
    border-color: var(--secondary-blue);
    background-color: transparent;
  }

  &__error {
    font-size: 1rem;
    line-height: 1;

    @include tablet {
      font-size: 0.8rem;
    }
  }
}
