@import "../../../assets/styles/mixins";

.main-page-header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include tablet {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  &__navigation-wrapper {
    display: flex;
    white-space: nowrap;
  }

  &__navigation {
    display: flex;
    align-items: center;

    @include tablet {
      display: none;
    }

    & > a {
      padding: 0;
      color: white;
      border: none;
      background-color: transparent;
      text-decoration: none;

      &:not(:last-child) {
        margin-right: 1.5rem;
      }
    }
  }

  & .logo {
    margin-right: 2rem;

    @include tablet {
      margin: 0;
    }
  }

  &__buttons-wrapper {
    display: flex;
    align-items: center;
    white-space: nowrap;

    @include tablet {
      display: none;
    }

    &_mobile {
      flex-direction: column;
    }
  }

  &__mobile-menu-button {
    display: none;
    height: 3.5rem;
    width: 3.5rem;
    border-radius: 0.5rem;
    background-color: var(--secondary-blue);
    border-color: var(--secondary-blue);

    & > img {
      height: 1.3rem;
      width: 1.3rem;
    }

    @include tablet {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
