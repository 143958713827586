@import "./mixins";

.input {
  width: 100%;
  border: 0.05rem solid rgba(44, 44, 44, 0.7);
  border-radius: 0.25rem;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.2rem;
  padding: 1rem 1.2rem;
  margin-bottom: 1.2rem;
  resize: none;

  @include tablet {
    font-size: 24px;
    line-height: 36px;
    padding: 10px 18px;
    margin-bottom: 12px;
  }

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: none;
    margin: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--main-red);
    border-radius: 10px;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
  }
}
