@import "../../../assets/styles/_mixins.scss";

.benefits-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 3rem;

  @include tablet {
    column-gap: 1.5rem;
  }

  @include mobile {
    display: none;
  }
}

.benefits-slider {
  display: none;
  margin: 0 -13rem !important;
  padding: 0 13rem !important;

  @include mobile {
    display: block;
  }
}

.benefits-card {
  display: grid;
  grid-template-rows: 25rem auto 1rem;

  @include tablet {
    grid-template-rows: 23rem auto 2rem;
  }

  @include mobile {
    min-height: 48rem;
    grid-template-rows: 30rem auto 2rem;
  }

  &__background {
    width: 100%;
    margin-bottom: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f2f4f7;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__icon-wrapper {
    height: 10rem;
    width: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border: 3px solid rgba(19, 107, 254, 0.15);
    border-radius: 9999px;
  }

  &__icon {
    width: 5rem;
  }

  // &__content {
  //   display: flex;
  //   flex-direction: column;
  //   justify-content: space-between;
  // }

  &__title {
    // white-space: nowrap;
    @include mobile {
      font-weight: 700;
    }
  }

  &__text {
    color: #626f84;

    @include mobile {
      font-size: large;
    }
  }

  &__id {
    color: #626f84;
    margin-bottom: 0;
    line-height: 1;

    @include mobile {
      font-size: 1.7rem;
    }
  }
}
