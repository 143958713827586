@import "../../../assets/styles/mixins";

.slider {
  margin: 0 -13rem;
  padding: 0 13rem;

  @include tablet {
    margin: 0;
    padding: 0;
  }
}

.slide {
  position: relative;
  height: 30rem;
  padding: 3.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: solid 3px rgba(108, 123, 138, 0.17);
  border-radius: 0.5rem;
  overflow: hidden;
  background-color: white;

  @include mobile {
    height: 54rem;
    padding: 3rem 2rem;
    border-radius: 1.5rem;
  }

  &__name {
    margin-bottom: 1rem;
    font-size: 0.9rem;
    font-weight: 400;
    letter-spacing: 0.05rem;
    text-transform: uppercase;
    color: var(--main-blue);

    @include mobile {
      font-size: small;
      font-weight: 500;
    }
  }

  &__title {
    width: 60%;
    margin-bottom: 1rem;
    font-size: 2.2rem;

    @include tablet {
      width: 70%;
      font-size: 2rem;
    }

    @include mobile {
      width: auto;
    }
  }

  &__text {
    width: 40%;
    font-size: 1rem;
    color: #576275;

    @include mobile {
      width: auto;
    }
  }

  &__navigation {
    margin-top: 1rem;
    display: flex;
    align-items: center;

    & > p {
      margin: 0;
      margin-left: 1.5rem;
      font-size: 0.8rem;
      font-weight: 600;
      text-transform: uppercase;

      @include mobile {
        margin-left: 2rem;
        font-size: 1rem;
        font-weight: 500;
      }
    }
  }

  &__image {
    position: absolute;
    top: 50%;
    right: -20rem;
    transform: translateY(-50%);
    width: 45rem;
    height: 45rem;
    border-radius: 9999px;
    display: flex;
    align-items: center;
    background-color: var(--main-blue);

    @include tablet {
      right: -28rem;
      height: 53rem;
      border-radius: 100%;
    }

    @include mobile {
      top: 100%;
      left: -25%;
      transform: translateY(-50%);
      height: 45rem;
      width: 150%;
      justify-content: center;
      align-items: flex-start;
    }

    & > img {
      width: 10rem;
      margin-left: 8rem;

      @include tablet {
        width: 8rem;
        margin-left: 5rem;
      }

      @include mobile {
        margin: 0;
        margin-top: 5rem;
        width: 12rem;
      }
    }
  }
}
