@import "../../assets/styles/mixins";

.navigation {
  display: flex;
  align-items: center;

  &_mobile {
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 1023px) {
      display: none;
    }
  }

  @include tablet {
    display: none;
  }

  &__link {
    text-decoration: none;
    color: white;

    &:not(:last-child) {
      margin-right: 2rem;
    }

    &:hover {
      color: white;
      text-decoration: underline;
      text-decoration-thickness: 0.05rem;
      text-underline-offset: 0.2rem;
      text-decoration-color: white;
    }

    &_mobile {
      width: 100%;
      margin: 0;
      padding: 2rem 0;
      border-bottom: 0.05rem solid rgba(255, 255, 255, 0.12);
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 1.5rem;

      & > img {
        width: 2rem;
        transform: rotate(90deg);
      }
    }
  }
}
