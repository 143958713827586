@import "../../assets/styles/mixins";

.mobile-menu {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  height: 100vh;
  width: 30rem;
  padding: 8rem 25px 1.5rem 25px;
  background-color: #1a154c;
  overflow: auto;

  @include tablet {
    display: block;
  }

  @include mobile {
    width: 100%;
  }

  &__background {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);

    @include tablet {
      display: block;
    }
  }

  &__header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 6rem;
    padding: 0 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > h1 {
      color: white;
    }
  }

  &__close-button {
    height: 3.5rem;
    width: 3.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem;
    background-color: var(--secondary-blue);
    border-color: var(--secondary-blue);

    & > img {
      height: 1.3rem;
      width: 1.3rem;
    }

    @media screen and (min-width: 1023px) {
      display: none;
    }
  }

  &__buttons-wrapper {
    display: flex;
    flex-direction: column;
  }

  &__button {
    width: 100%;
    margin: 0;
    margin-bottom: 1rem;
    padding: 1.5rem 0;
    display: flex;
    justify-content: center;
    align-items: center;

    & > img {
      width: 1.3rem;
      margin-right: 0.7rem;
    }

    & > p {
      font-size: 1.2rem;
    }
  }
}
