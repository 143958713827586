//--------DEVICE SIZE--------
$small-mobile: 375px;
$tablet-width: 648px;
$desktop-width: 1023px;
$container-width: 1440px;

@mixin small-mobile {
  @media (max-width: #{$small-mobile - 1px}) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: #{$desktop-width}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width + 1px}) {
    @content;
  }
}

@mixin container {
  @media (min-width: #{$desktop-width}) and (max-width: #{$container-width}) {
    @content;
  }
}
