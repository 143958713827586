:root {
  // ===== COLORS =========
  --main-red: #c05458;
  --main-black: #2c2c2c;
  --main-white: #fdfdfd;
  --main-blue: #3c7cf6;
  --secondary-blue: #136bfe;
  --main-green: #54a316;

  // ==== WIDTH =========
  --container-width: calc(1440px + 60px);
}
