.button {
  padding: 0.5rem 1rem;
  border-color: white;
  border-radius: 0.3rem;
  display: flex;
  align-items: center;
  color: white;
  background-color: var(--secondary-blue);
  border: 0.1rem solid var(--secondary-blue);
  transition: all linear 0.2s;

  &:disabled {
    color: #bbbbbb;
    border-color: #ececec;
    background-color: #ececec;
  }

  & > img {
    margin-right: 0.5rem;
  }

  & > p {
    margin: 0;
    line-height: normal;
  }

  &_secondary {
    color: var(--secondary-blue);
    background-color: transparent;
    border-color: var(--secondary-blue);

    &:hover {
      color: white;
      background-color: var(--secondary-blue);
    }
  }

  &_green {
    background-color: var(--main-green);
    border-color: var(--main-green);
  }

  &_transparent {
    border-color: white;
    background-color: transparent;
  }

  &_outline {
    border-color: var(--secondary-blue);
    background-color: transparent;
    color: black;
  }
}
