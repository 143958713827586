@import "../../assets/styles/mixins";

.search-page {
  padding-top: 0.6rem;
  padding-bottom: 4.4rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include tablet {
    padding-top: 24px;
    padding-bottom: 48px;
  }

  &__logo {
    width: 29rem;
    font-style: normal;
    font-weight: 400;
    font-size: 3.6rem;
    line-height: 4.2rem;
    color: var(--main-red);
    display: flex;
    align-items: center;

    @include tablet {
      font-size: 36px;
      line-height: 48px;
      width: auto;
    }

    & > img {
      width: 7.15rem;
      margin-right: 1.7rem;

      @include tablet {
        width: 82px;
        margin-right: 12px;
      }
    }
  }

  &__search-wrapper {
    margin-top: 2.5rem;
    width: 42rem;

    @include tablet {
      width: 100%;
      margin-top: 60px;
    }
  }

  &__descr {
    margin-top: 5rem;
    padding: 0 7rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    @include tablet {
      margin-top: 48px;
      padding: unset;
      flex-direction: column;
    }
  }

  &__about {
    max-width: 50%;

    @include tablet {
      max-width: none;
      padding-bottom: 10px;
    }

    & > h2 {
      display: inline-block;
      text-align: left;
      color: var(--main-black);
      border-bottom: 0.15rem solid var(--main-red);
    }

    & > p {
      text-align: left;
      font-size: 1.2rem;
      line-height: 2rem;
      margin: 0;
      margin-top: 1rem;

      @include tablet {
        font-size: 16px;
        line-height: 18px;
        margin-top: 24px;
      }
    }
  }

  &__iframe-container {
    position: relative;
    margin-left: 1.75rem;
    margin-right: 0;
    width: inherit;

    @include tablet {
      border-radius: 9px;
      margin: 0;
      margin-bottom: 12px;
      width: 80%;
      order: -1;
      height: unset;
    }
  }

  & .video-js {
    font-size: 0.7rem;
    border-radius: 0.3rem;
    overflow: hidden;
  }

  & .vjs-control-bar {
    border-bottom-left-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
  }

  & .vjs-volume-panel {
    width: 4rem !important;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.8rem;

    &:hover {
      width: 4rem !important;
    }
  }
}
