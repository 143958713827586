@import "../../assets/styles/mixins";

.mainPage {
  &__toggle-wrapper {
    margin-bottom: 2rem;

    @include mobile {
      display: flex;
      flex-direction: column;
    }
  }

  &__toggle {
    padding: 0.3rem 0.9rem;
    font-size: medium;
    border: 1px solid #a5a5a5;
    background-color: transparent;

    @include mobile {
      width: 100%;
      padding: 1rem 0;
      margin-bottom: 1rem;
    }

    &.left {
      margin-right: 0.5rem;
      padding-left: 1.2rem;
      border-radius: 9999px 150rem 150rem 9999px;

      @include mobile {
        margin-right: 0;
        padding-left: 0;
        border-radius: 0.5rem;
      }
    }

    &.right {
      padding-right: 1.2rem;
      border-radius: 150rem 9999px 9999px 150rem;

      @include mobile {
        padding-right: 0;
        border-radius: 0.5rem;
      }
    }

    &.active {
      color: white;
      background-color: #080341;
    }
  }

  &__contact-form-wrapper {
    position: relative;
    z-index: 1;
    padding: 0;
    padding-top: 3rem;
    padding-bottom: 6rem;

    @include tablet {
      padding-top: 3rem !important;
      padding-bottom: 7rem !important;
    }
  }

  &__instruction {
    text-align: center;

    & > p {
      font-size: medium;
      font-weight: 500;
      letter-spacing: 0.05rem;
      text-transform: uppercase;
      color: var(--main-blue);
    }

    & > h2 {
      margin-bottom: 2.5rem;
      font-size: 2.5rem;
      font-weight: 600;
    }
  }

  &__up-button {
    margin-bottom: 1rem;
    padding: 0.5rem 1rem;
    border: 1px solid rgba(98, 111, 132, 0.22);
    border-radius: 5px;
    color: #626f84;
    background-color: transparent;

    & > img {
      margin-left: 0.5rem;
    }

    &-wrapper {
      width: 100%;
      margin-top: 3rem;
      display: flex;
      justify-content: center;
    }
  }
}
