@import "../../assets/styles/mixins";

.warning-sign{
  display: flex;
  align-items: center;
  background: #FFA5021A;
  border-radius: 0.3rem;
  padding: 0.5rem 1rem;
  gap: 1rem;
  margin-bottom: 1rem;

  & > img {
    width: 1.5rem;
    height: 1.5rem;
  }

  &__text {
    margin: 0;
    padding: 0;
    color: #FFA502;
    font-size: 0.7rem;
  }

  @include mobile {
    &__text {
      font-size: 0.8rem;
    }
  }
}