@import "../../../assets/styles/mixins";

.mainPageHero {
  margin-bottom: 5rem;
  height: 50rem;
  padding-top: 5rem;
  display: flex;
  align-items: center;
  background-image: url("../../../assets/images/main-page/hero.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @include tablet {
    background-image: url("../../../assets/images/main-page/hero-tablet.png");
  }

  @include mobile {
    height: 65rem;
    padding-top: 0;
    background-image: url("../../../assets/images/main-page/hero-mobile.svg");
  }

  &__wrapper {
    width: 45rem;

    @include tablet {
      width: 100%;
    }

    & > h1 {
      margin-bottom: 1rem;
      font-size: 3rem;
      color: white;

      & > span {
        text-decoration: underline;
        text-decoration-thickness: 0.1rem;
        text-underline-offset: 0.3rem;
      }
    }

    & > p {
      margin-bottom: 2rem;
      color: white;

      @include mobile {
        margin-bottom: 4rem;
        font-size: 1.2rem;
      }
    }
  }
}
