@import "../../../assets/styles/mixins";

.documents-list {
  margin-bottom: 7rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.5rem;

  @include tablet {
    grid-template-columns: repeat(2, 1fr);
  }

  @include mobile {
    display: flex;
    flex-direction: column;
  }

  &__empty {
    width: 100%;
    padding: 15rem 0;
    text-align: center;

    & > p {
      font-size: 2rem;
    }
  }
}
