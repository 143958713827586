@import "../../assets/styles/mixins";

.contact {
  padding-bottom: 6.8rem;

  @include tablet {
    padding-top: 24px;
    padding-bottom: 48px;
  }

  &__form {
    margin: 0 auto;
    margin-top: 1.3rem;
    width: 35rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include tablet {
      width: 100%;
      margin-top: 12px;
    }

    & p {
      margin-bottom: 24px;

      @include tablet {
        margin-bottom: 36px;
        font-size: 24px;
        line-height: 28px;
      }
    }

    & small {
      font-size: 0.8rem;
      line-height: 1rem;

      @include tablet {
        font-size: 14px;
        line-height: 16px;
      }
    }
  }

  &__form-btns {
    margin-top: 1rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 1.4rem;

    @include tablet {
      margin-top: 24px;
      column-gap: 15px;
    }

    & > button {
      font-size: 1.2rem;
      padding: 1.15rem 3.6rem;

      @include tablet {
        font-size: 18px;
        line-height: 24px;
        padding: 16px 48px;
      }
    }
  }

  &__btn {
    margin-top: 1rem;
    font-size: 1.2rem;
    padding: 1.15rem 3.6rem;
    width: 100%;
  }
}
