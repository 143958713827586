@import "./mixins";

.title {
  font-style: normal;
  font-weight: 400;
  font-size: 2.4rem;
  line-height: 3rem;
  color: var(--main-red);
  text-align: center;

  @include tablet {
    font-size: 36px;
    line-height: 48px;
  }

  & > span {
    color: var(--main-black);
  }
}

.subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.8rem;
  text-align: center;
  color: var(--main-black);

  @include tablet {
    font-size: 28px;
    line-height: 36px;
  }

  & > span {
    color: var(--main-red);
  }
}
